import { useDebounce } from '@react-hook/debounce';
import { MotionValue, useSpring, useTransform } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIsTablet } from '~/_shared/hooks/useMedia';
import { ChildrenProps } from '~/config-hcp';

type TopNavContextValue = {
  xPos: MotionValue<number> | null;
  fillXPos: MotionValue<number> | null;
  sizY: MotionValue<number> | null;

  selectedIndex: number;
  setSelectedIndex: (i: number) => void;
  selectedChildIndex: number;
  setSelectedChildIndex: (i: number) => void;
  hoveredIndex: number;
  setHoveredIndex: (i: number) => void;
  itemWidths: number[];
  setItemWidths: Dispatch<SetStateAction<number[]>>;
  itemOffsets: number[];
  setItemOffsets: Dispatch<SetStateAction<number[]>>;
  firstChildActive: boolean; // Is the first dropdown item active or hovered?
  setFirstChildActive: Dispatch<SetStateAction<boolean>>;

  topLevelData: (NavItemNode | NavParentNode)[];

  mobileNavVisible: boolean;
  mobileNavOpened: boolean;
  toggleMobileNav: () => void;
  closeMobileNav: () => void;
};

export const TopNavConfig = {
  gap: 64,
  sizX: 32,
  sizYMax: 20,
  spaceBetween: 6,
  dropdownGap: 10,
} as const;

export const TopNavContext = createContext<TopNavContextValue>({
  xPos: null,
  fillXPos: null,
  sizY: null,
  selectedIndex: -1,
  setSelectedIndex: () => {},
  selectedChildIndex: -1,
  setSelectedChildIndex: () => {},
  hoveredIndex: -1,
  setHoveredIndex: () => {},
  itemWidths: [],
  setItemWidths: () => {},
  itemOffsets: [],
  setItemOffsets: () => {},
  firstChildActive: false,
  setFirstChildActive: () => {},

  topLevelData: [],

  mobileNavVisible: false,
  mobileNavOpened: false,
  toggleMobileNav: () => {},
  closeMobileNav: () => {},
});

export const TopNavContextProvider: FC<{ path: string } & ChildrenProps> = ({
  path,
  children,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [selectedChildIndex, setSelectedChildIndex] = useState(-1);
  const [hoveredIndex, setHoveredIndex] = useDebounce(-1, 150);
  const [itemWidths, setItemWidths] = useState<number[]>([]);
  const [itemOffsets, setItemOffsets] = useState<number[]>([]);
  const [firstChildActive, setFirstChildActive] = useState(false);

  // Mobile nav-related
  const [mobileNavOpened, setMobileNavOpened] = useState(false);
  const mobileNavVisible = useIsTablet();
  const toggleMobileNav = () => {
    setMobileNavOpened(!mobileNavOpened);
    document.body.classList.toggle('not-scrolling');
  };
  const closeMobileNav = () => {
    setMobileNavOpened(false);
    document.body.classList.remove('not-scrolling');
  };

  // For inside the bumpy boi
  const xPos = useSpring(420, { damping: 18 }); // Start the bump in the middle
  const fillXPos = useTransform(xPos, (x) => x + TopNavConfig.spaceBetween);
  const sizY = useSpring(0, { damping: 10 });

  // Menu data
  // To get menu content
  const _data = useStaticQuery<Queries.AllTopNavContextQuery>(graphql`
    query AllTopNavContext {
      allSitePage(
        filter: { pageContext: { order: { gt: 0 } } }
        sort: { fields: pageContext___order }
      ) {
        nodes {
          path
          pageContext {
            order
            title
            button
            parent
          }
        }
      }
    }
  `);
  const data = useMemo(
    () => [
      ..._data.allSitePage.nodes,
      {
        path: 'https://prevalence.jelmyto.com',
        pageContext: {
          parent: 'Resources',
          title: 'Find JELMYTO users',
          button: 'Find JELMYTO users',
          order: 63,
        },
      },
    ],
    [_data],
  );

  // for items with children, filter out subsequent items with the same parents
  const topLevelData = useMemo(
    () =>
      data
        .filter(
          (p, i) =>
            !p.pageContext?.parent ||
            i === 0 ||
            data[i - 1].pageContext?.parent !== p.pageContext.parent,
        )
        .map((p) => {
          if (p.pageContext?.parent) {
            const children = data.filter(
              (c) => c.pageContext?.parent === p.pageContext?.parent,
            );

            // Get the first directory of the path
            const pathParts = p.path.match(/(\/[^\/]+\/)/);

            return {
              path: pathParts?.length ? pathParts[1] : p.path,
              pageContext: {
                ...p.pageContext,
                order: p.pageContext.order,
                button: p.pageContext.parent,
                children,
              },
            };
          }
          return p;
        }),
    [data],
  );

  useEffect(() => {
    // On a route change, update the selected index with the current page
    const i = topLevelData.findIndex((p) => path.startsWith(p.path));
    setSelectedIndex(i);

    // And if it's a subpage, update that index too
    const n = topLevelData[i];
    if (isNavParentNode(n) && n.pageContext) {
      const c = n.pageContext.children.findIndex((p) => p.path === path);
      setSelectedChildIndex(c);
    } else {
      setSelectedChildIndex(-1);
    }

    // Finally, deselect the menu item to hide the dropdown (for touch devices)
    setHoveredIndex(-1);
  }, [
    path,
    setSelectedIndex,
    setSelectedChildIndex,
    topLevelData,
    setHoveredIndex,
  ]);

  useEffect(() => {
    const setBump = (i: number) => {
      if (i > -1 && xPos && sizY) {
        const x = itemOffsets[i] + itemWidths[i] / 2 - TopNavConfig.sizX;
        // Checking for an actual change here helps prevent the context re-rendering
        if (xPos.get() !== x) {
          xPos.set(x);
          sizY.set(20);
        }
      } else if (i === -1) {
        // Hide the bump when no pages are active (homepage, sitemap, etc.)
        if (xPos.get() !== i) {
          sizY.set(0);
        }
      }
    };

    setBump(hoveredIndex > -1 ? hoveredIndex : selectedIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredIndex, selectedIndex, itemOffsets]);

  return (
    <TopNavContext.Provider
      value={{
        xPos,
        fillXPos,
        sizY,
        selectedIndex,
        setSelectedIndex,
        selectedChildIndex,
        setSelectedChildIndex,
        hoveredIndex,
        setHoveredIndex,
        firstChildActive,
        setFirstChildActive,
        itemWidths,
        setItemWidths,
        itemOffsets,
        setItemOffsets,
        topLevelData,
        mobileNavOpened,
        mobileNavVisible,
        toggleMobileNav,
        closeMobileNav,
      }}
    >
      {children}
    </TopNavContext.Provider>
  );
};

export type NavItemNode =
  Queries.AllTopNavContextQuery['allSitePage']['nodes'][number];
export type NavParentNode = NavItemNode & {
  pageContext: NavItemNode['pageContext'] & {
    children: NavItemNode[];
  };
};

export const isNavParentNode = (n: NavItemNode): n is NavParentNode =>
  n && n.pageContext !== null && n.pageContext.hasOwnProperty('children');

//TopNavContextProvider.whyDidYouRender = true;
