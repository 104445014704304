import { withPrefix } from 'gatsby';
import { FC, createContext, useContext, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { ChildrenProps } from '~/_shared/config/types';
import { isSSG } from '~/utils';
import imgArrowRight from '../images/hcp-arrow-right.png';
import imgJelmytoLogo from '../images/jelmyto-logo.png';

type IsHcpContextValue = {
  isHCP: boolean;
  setIsHCP: React.Dispatch<React.SetStateAction<boolean>>;
  handlePopClickYes: () => void;
  handlePopClickNo: () => void;
  isOpen: boolean;
};

const IsHcpContext = createContext<IsHcpContextValue>({
  isHCP: false,
  setIsHCP: () => {},
  handlePopClickYes: () => {},
  handlePopClickNo: () => {},
  isOpen: false,
});

type IsHCPContextProviderProps = ChildrenProps & IsHcpContextValue;

const IsHcpContextProvider: FC<IsHCPContextProviderProps> = ({ children }) => {
  const [userIsHCP, setUserIsHCP] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!isSSG) {
      const lastVisited = window.localStorage.getItem('patientOrHcp');
      if (lastVisited === 'hcp') {
        setUserIsHCP(true);
        setIsOpen(false);
      } else {
        setUserIsHCP(false);
        setIsOpen(true);
      }
    }
  }, [setIsOpen, setUserIsHCP]);

  const handlePopClickYes = () => {
    localStorage.setItem('patientOrHcp', 'hcp');
    setUserIsHCP(true);
    setIsOpen(false);
  };

  const handlePopClickNo = () => {
    localStorage.setItem('patientOrHcp', 'patient');

    // don't use Gatsby's navgiate() here as it'll prefix it with /hcp/
    window.location.href = 'https://jelmyto.com/patient/';
  };

  const context: IsHcpContextValue = {
    isHCP: userIsHCP,
    setIsHCP: setUserIsHCP,
    handlePopClickYes: handlePopClickYes,
    handlePopClickNo: handlePopClickNo,
    isOpen: isOpen,
  };

  return (
    <IsHcpContext.Provider value={context}>{children}</IsHcpContext.Provider>
  );
};

const useIsHcpContext = (): IsHcpContextValue => {
  const isHcpContextCtx = useContext(IsHcpContext);
  return isHcpContextCtx;
};

export { IsHcpContext, IsHcpContextProvider, useIsHcpContext };

export const IsHCPPopup = () => {
  const { isOpen, handlePopClickNo, handlePopClickYes } =
    useContext(IsHcpContext);

  return (
    <Popup open={isOpen} closeOnDocumentClick={false}>
      <div id="HCP-popup">
        <img
          src={withPrefix(imgJelmytoLogo)}
          id="logo-popup"
          alt="JELMYTO logo"
        />
        <h3 className="popup-headline">
          Are you a healthcare professional in the US?
        </h3>
        <div className="popup-ui">
          <button
            onClick={handlePopClickNo}
            className="popup-ui-no return-to-home"
          >
            NO
            <img src={withPrefix(imgArrowRight)} alt="Right arrow" />
          </button>
          <button
            onClick={handlePopClickYes}
            className="popup-ui-yes return-to-home"
          >
            YES
            <img src={withPrefix(imgArrowRight)} alt="Right arrow" />
          </button>
          <br style={{ clear: 'both' }} />
        </div>
      </div>
    </Popup>
  );
};
