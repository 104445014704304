import { JELMYTO } from '~/_shared/components/common/typography';
import { staticAssetPath } from '~/utils';
import { VideoProps } from './types';

export type VideoNames = keyof typeof hcpVideoData;

// by only typing the values, we get full inference on the keys
// DO NOT RENAME KEYS! They're tied to redirects/deeplinks and button ids
export const hcpVideoData = {
  admin: {
    name: 'Instillation With a Ureteral Catheter',
    description: (
      <>
        Step-by-step instructional video for administering <JELMYTO /> via
        ureteral catheter.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/admin/admin-2022.mp4'),
    webm: staticAssetPath('/video/hcp/admin/admin-2022.webm'),
    captions: {
      src: staticAssetPath('/video/hcp/admin/admin-2022.vtt'),
    },
    poster: staticAssetPath('/video/hcp/admin/images/admin-poster.jpg'),
    chapters: [
      {
        num: 1,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-01.jpg',
        ),
        start: '00:00',
        end: '01:06',
      },
      {
        num: 2,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-02.jpg',
        ),
        start: '01:06',
        end: '02:50',
      },
      {
        num: 3,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-03.jpg',
        ),
        start: '02:50',
        end: '04:02',
      },
      {
        num: 4,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-04.jpg',
        ),
        start: '04:02',
        end: '05:12',
      },
      {
        num: 5,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-05.jpg',
        ),
        start: '05:12',
        end: '05:33',
      },
      {
        num: 6,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-06.jpg',
        ),
        start: '05:33',
        end: '05:54',
      },
      {
        num: 7,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-07.jpg',
        ),
        start: '05:54',
        end: '07:14',
      },
      {
        num: 8,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-08.jpg',
        ),
        start: '07:14',
        end: '08:40',
      },
      {
        num: 9,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-09.jpg',
        ),
        start: '08:40',
        end: '10:20',
      },
      {
        num: 10,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-isi.jpg',
        ),
        start: '10:20',
        end: '15:03',
      },
    ],
  },
  community: {
    name: <>Reimbursement in the Community Setting</>,
    description: <>An in-depth perspective from Dr. Jason Hafron, CMO.</>,
    mp4: staticAssetPath('/video/hcp/hopd/reimbursement-community.mp4'),
    webm: staticAssetPath('/video/hcp/hopd/reimbursement-community.webm'),
    poster: staticAssetPath(
      '/video/hcp/hopd/images/reimbursement-community.jpg',
    ),
  },
  drScherr: {
    name: 'Dr. Douglas Scherr',
    description: (
      <>
        Discusses his experience using <JELMYTO /> in the <em>My Why</em>{' '}
        series.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/my-why/my-why-dr-scherr.mp4'),
    webm: staticAssetPath('/video/hcp/my-why/my-why-dr-scherr.webm'),
    poster: staticAssetPath(
      '/video/hcp/my-why/images/dr-douglas-scherr-thumb.jpg',
    ),
    captions: {
      src: '',
    },
  },
  drMurray: {
    name: 'Dr. Katie Murray',
    description: (
      <>
        Discusses her experience using <JELMYTO /> in the <em>My Why</em>{' '}
        series.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/my-why/my-why-dr-murray.mp4'),
    webm: staticAssetPath('/video/hcp/my-why/my-why-dr-murray.webm'),
    poster: staticAssetPath(
      '/video/hcp/my-why/images/dr-katie-murray-thumb.jpg',
    ),
    captions: {
      src: '',
    },
  },
  drBasham: {
    name: 'Dr. Kyle Basham',
    description: (
      <>
        Discusses his experience using <JELMYTO /> in the <em>My Why</em>{' '}
        series.
      </>
    ),
    mp4: '',
    webm: '',
    poster: staticAssetPath(
      '/video/hcp/my-why/images/dr-kyle-basham-thumb.jpg',
    ),
    captions: {
      src: '',
    },
  },
  drJacob: {
    name: 'Dr. Joseph Jacob',
    description: (
      <>
        Discusses his experience using <JELMYTO /> in the <em>My Why</em>{' '}
        series.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/my-why/my-why-dr-jacob.mp4'),
    webm: staticAssetPath('/video/hcp/my-why/my-why-dr-jacob.webm'),
    poster: staticAssetPath('/video/hcp/my-why/images/dr-jacob-poster.png'),
    captions: {
      src: '',
    },
  },
  hopd: {
    name: <>Reimbursement in the HOPD Setting</>,
    description: (
      <>An in-depth perspective from Jacob Kettle, PharmD, BCOP and Director.</>
    ),
    mp4: staticAssetPath('/video/hcp/hopd/reimbursement-hopd.mp4'),
    webm: staticAssetPath('/video/hcp/hopd/reimbursement-hopd.webm'),
    poster: staticAssetPath('/video/hcp/hopd/images/reimbursement-hopd.jpg'),
  },
  kathysJourney: {
    name: "Kathy's Tomorrow",
    description: (
      <>
        Kathy Gregory underwent multiple procedures for years, until <JELMYTO />{' '}
        came into the picture.
      </>
    ),

    mp4: staticAssetPath('/video/patient/kathys-journey/my-tomorrow-kathy.mp4'),
    webm: staticAssetPath(
      '/video/patient/kathys-journey/my-tomorrow-kathy.webm',
    ),
    poster: staticAssetPath(
      '/video/patient/kathys-journey/images/kathy-poster.png',
    ),
    captions: {
      src: staticAssetPath(
        '/video/patient/kathys-journey/my-tomorrow-kathy-captions-en.vtt',
      ),
    },
  },
  mikesJourney: {
    name: "Mike's Tomorrow",
    description: (
      <>
        Mike Mason was on the verge of dialysis. Then he found out about{' '}
        <JELMYTO /> as a treatment option.
      </>
    ),
    mp4: staticAssetPath('/video/patient/my-tomorrow/my-tomorrow-mike.mp4'),
    webm: staticAssetPath('/video/patient/my-tomorrow/my-tomorrow-mike.webm'),
    poster: staticAssetPath(
      '/video/patient/my-tomorrow/images/mike-interview-shot.png',
    ),
    captions: {
      src: staticAssetPath(
        '/video/patient/my-tomorrow/my-tomorrow-captions-en.vtt',
      ),
    },
  },
  mode: {
    name: (
      <>
        <JELMYTO /> Technology
      </>
    ),
    description: (
      <>
        See how the technology of <JELMYTO /> makes chemoablation possible.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/mode/MODe_FINAL_RTgel_SEP2023-V03C.mp4'),
    webm: staticAssetPath('/video/hcp/mode/MODe_FINAL_RTgel_SEP2023-V03C.webm'),
    poster: staticAssetPath('/video/hcp/mode/images/mode-poster.jpg'),
    captions: {
      src: staticAssetPath(
        '/video/hcp/mode/captions/mechanism-of-delivery-OCT2022.vtt',
      ),
      lang: 'en',
      default: false,
    },
  },
  nephrostomy: {
    name: 'Instillation With a Nephrostomy Tube',
    description: (
      <>
        Step-by-step instructional video for administering <JELMYTO /> via
        nephrostomy tube.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/admin/nephrostomy-2023.mp4'),
    webm: staticAssetPath('/video/hcp/admin/nephrostomy-2023.webm'),
    poster: staticAssetPath('/video/hcp/admin/images/nephrostomy-poster.png'),
    captions: {
      src: staticAssetPath('/video/hcp/admin/nephrostomy-2023.vtt'),
    },
    chapters: [
      {
        num: 1,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/nephrostomy-poster.png',
        ),
        start: '00:00',
        end: '01:11',
      },
      {
        num: 2,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-02.jpg',
        ),
        start: '01:11',
        end: '02:56',
      },
      {
        num: 3,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-03.jpg',
        ),
        start: '02:56',
        end: '04:17',
      },
      {
        num: 4,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-04.jpg',
        ),
        start: '04:17',
        end: '05:35',
      },
      {
        num: 5,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-05.jpg',
        ),
        start: '05:35',
        end: '06:31',
      },
      {
        num: 6,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-06.jpg',
        ),
        start: '06:31',
        end: '06:42',
      },
      {
        num: 7,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-07.jpg',
        ),
        start: '06:42',
        end: '08:03',
      },
      {
        num: 8,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-08.jpg',
        ),
        start: '08:03',
        end: '09:50',
      },
      {
        num: 9,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-09.jpg',
        ),
        start: '09:50',
        end: '11:30',
      },
      {
        num: 10,
        thumb: staticAssetPath(
          '/video/hcp/admin/images/web-thumb-admin-isi.jpg',
        ),
        start: '11:30',
        end: '16:20',
      },
    ],
  },

  preparation: {
    name: 'Instructional Video Pharmacy',
    description: (
      <>
        Step-by-step instructional video for preparing <JELMYTO />.
      </>
    ),
    mp4: staticAssetPath('/video/hcp/preparation/preparation-2022.mp4'),
    webm: staticAssetPath('/video/hcp/preparation/preparation-2022.webm'),
    captions: {
      src: staticAssetPath('/video/hcp/preparation/preparation-2022.vtt'),
    },
    poster: staticAssetPath(
      '/video/hcp/preparation/images/preparation-poster.jpg',
    ),
    chapters: [
      {
        num: 1,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-01.jpg',
        ),
        start: '00:00',
        end: '00:55',
      },
      {
        num: 2,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-02.jpg',
        ),
        start: '00:55',
        end: '02:13',
      },
      {
        num: 3,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-03.jpg',
        ),
        start: '02:13',
        end: '03:58',
      },
      {
        num: 4,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-04.jpg',
        ),
        start: '03:58',
        end: '04:26',
      },
      {
        num: 5,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-05.jpg',
        ),
        start: '04:26',
        end: '05:50',
      },
      {
        num: 6,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-06.jpg',
        ),
        start: '05:50',
        end: '07:11',
      },
      {
        num: 7,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-07.jpg',
        ),
        start: '07:11',
        end: '09:44',
      },
      {
        num: 8,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-08.jpg',
        ),
        start: '09:44',
        end: '11:00',
      },
      {
        num: 9,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-09.jpg',
        ),
        start: '11:00',
        end: '11:38',
      },
      {
        num: 10,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-10.jpg',
        ),
        start: '11:38',
        end: '12:06',
      },
      {
        num: 11,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-11.jpg',
        ),
        start: '12:06',
        end: '13:41',
      },
      {
        num: 12,
        thumb: staticAssetPath(
          '/video/hcp/preparation/images/web-thumb-prep-12.jpg',
        ),
        start: '13:41',
        end: '18:23',
      },
    ],
  },
  speaker: {
    name: <></>,
    description: <></>,
    mp4: staticAssetPath('/video/hcp/home/linehan-04.mp4'),
    webm: staticAssetPath('/video/hcp/home/linehan-04.webm'),
    poster: staticAssetPath('/video/hcp/home/images/linehan.png'),
    captions: { src: staticAssetPath('/video/hcp/home/linehan-04.vtt') },
  },
} satisfies Record<string, VideoProps>;
