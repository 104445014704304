exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-how-jelmyto-works-tsx": () => import("./../../../src/pages/about/how-jelmyto-works.tsx" /* webpackChunkName: "component---src-pages-about-how-jelmyto-works-tsx" */),
  "component---src-pages-about-who-jelmyto-is-for-tsx": () => import("./../../../src/pages/about/who-jelmyto-is-for.tsx" /* webpackChunkName: "component---src-pages-about-who-jelmyto-is-for-tsx" */),
  "component---src-pages-access-support-coverage-and-reimbursement-tsx": () => import("./../../../src/pages/access-support/coverage-and-reimbursement.tsx" /* webpackChunkName: "component---src-pages-access-support-coverage-and-reimbursement-tsx" */),
  "component---src-pages-access-support-new-customers-tsx": () => import("./../../../src/pages/access-support/new-customers.tsx" /* webpackChunkName: "component---src-pages-access-support-new-customers-tsx" */),
  "component---src-pages-access-support-support-services-tsx": () => import("./../../../src/pages/access-support/support-services.tsx" /* webpackChunkName: "component---src-pages-access-support-support-services-tsx" */),
  "component---src-pages-clinical-information-clinical-results-tsx": () => import("./../../../src/pages/clinical-information/clinical-results.tsx" /* webpackChunkName: "component---src-pages-clinical-information-clinical-results-tsx" */),
  "component---src-pages-clinical-information-trial-information-tsx": () => import("./../../../src/pages/clinical-information/trial-information.tsx" /* webpackChunkName: "component---src-pages-clinical-information-trial-information-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-important-safety-information-tsx": () => import("./../../../src/pages/important-safety-information.tsx" /* webpackChunkName: "component---src-pages-important-safety-information-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preparation-and-administration-administration-overview-tsx": () => import("./../../../src/pages/preparation-and-administration/administration-overview.tsx" /* webpackChunkName: "component---src-pages-preparation-and-administration-administration-overview-tsx" */),
  "component---src-pages-preparation-and-administration-administration-tsx": () => import("./../../../src/pages/preparation-and-administration/administration.tsx" /* webpackChunkName: "component---src-pages-preparation-and-administration-administration-tsx" */),
  "component---src-pages-preparation-and-administration-preparation-tsx": () => import("./../../../src/pages/preparation-and-administration/preparation.tsx" /* webpackChunkName: "component---src-pages-preparation-and-administration-preparation-tsx" */),
  "component---src-pages-resources-resources-for-patients-tsx": () => import("./../../../src/pages/resources/resources-for-patients.tsx" /* webpackChunkName: "component---src-pages-resources-resources-for-patients-tsx" */),
  "component---src-pages-resources-resources-for-physicians-tsx": () => import("./../../../src/pages/resources/resources-for-physicians.tsx" /* webpackChunkName: "component---src-pages-resources-resources-for-physicians-tsx" */),
  "component---src-pages-rose-publication-tsx": () => import("./../../../src/pages/rose-publication.tsx" /* webpackChunkName: "component---src-pages-rose-publication-tsx" */),
  "component---src-pages-safety-tsx": () => import("./../../../src/pages/safety.tsx" /* webpackChunkName: "component---src-pages-safety-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-pages-woldu-publication-tsx": () => import("./../../../src/pages/woldu-publication.tsx" /* webpackChunkName: "component---src-pages-woldu-publication-tsx" */)
}

