module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.jelmyto.com/hcp","stripQueryString":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JELMYTO® (mitomycin) for pyelocalyceal solution","short_name":"Jelmyto.com","start_url":"/","background_color":"#4e4084","theme_color":"#4e4084","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ffce7b34896105d1227822114e70c808"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M6PGC7R","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"()=>([{consent:{default:{ad_storage:\"denied\",analytics_storage:\"denied\",wait_for_update:500}}},{set:{ads_data_redaction:!0}}].forEach((e=>window.dataLayer&&window.dataLayer.push(e))),{platform:\"gatsby\"})"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
