import { Variants } from 'framer-motion';

/** Callout animations */

/** Callout Wrapper (with gradient/grpahic background) animation */
export const calloutWrapperVariants: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.9,
    },
  },
};

/** Individual callout animation */
export const calloutVariants: Variants = {
  hidden: {
    opacity: 0,
    x: -40,
  },
  // Pass in interval via the framer "custom" prop
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transformOrigin: 'left',
    transition: {
      delay: i * 0.3,
      duration: 1,
      ease: 'easeOut',
    },
  }),
};

/** Inner callout text/img content animation */
export const innerCalloutVariants: Variants = {
  hidden: {
    opacity: 0,
    x: -8,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.85,
      duration: 0.45,
      ease: 'easeOut',
    },
  },
};
